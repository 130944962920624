import React, { useState, useEffect } from "react";
import { NavBar } from "../NavBar/navBar";
import { Footer } from "../footer/footer";
import CanonicalTag from "../canonicalTag";
import { MetaComponent } from "../meta/meta";
import { PopupModal } from "../Modal/modal";

export const Layout = ({
  children,
  ogGraph,
  twitterCard,
  Title,
  Description,
  script,
}) => {
  const [isMobile, setIsMobile] = useState(window.screen.width <= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (window && window.scrollTo) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    setIsMobile(window.screen.width <= 768);
  }, [isMobile]);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div className="mainPageContainer">
      <CanonicalTag />
      <NavBar isMobile={isMobile} handleModal={handleModal} />
      <MetaComponent
        ogGraph={ogGraph}
        twitterCard={twitterCard}
        Title={Title}
        Description={Description}
        script={script}
      />
      <PopupModal show={isModalOpen} handleModal={handleModal} />
      <div>{children}</div>
      <Footer isMobile={isMobile} handleModal={handleModal} />
    </div>
  );
};
