import React, { Component } from "react";
import { Modal, Form, FormControl, Container, Row, Col, FormGroup } from "react-bootstrap";
import { MetaComponent } from "../../Components/meta/meta";
import { validationSchema } from "./validateFields";


export class PopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      emailAddress: '',
      contactNo: '',
      contactFor: '',
      message: '',
      errors: {}
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handleClose = () => {
    this.setState({
      fullName: '',
      emailAddress: '',
      contactNo: '',
      contactFor: '',
      message: '',
      errors: {}
    });
    this.props.handleModal()
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget; // Store the form reference
    await this.setState({ errors: {} });

    const { fullName, emailAddress, contactNo, contactFor, message } = this.state;
    const isEmailProvided = !!emailAddress.trim();
    const isContactNoProvided = !!contactNo.trim();

    if (!isEmailProvided && !isContactNoProvided) {
      const errMsg = 'Please Provide either email or contact number'
      await this.setState({ errors: { phoneOrContact: errMsg } });
    }

    try {
      await validationSchema.validate({ fullName, emailAddress, contactNo, contactFor, message }, { abortEarly: false });
      if (Object.keys(this.state.errors).length === 0) {
        form.submit();
      } else {
        throw new Error('Invalid Details')
      }
    } catch (errors) {
      const validationErrors = {};
      errors.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...validationErrors },
      }))
    }
  };



  render() {
    const { fullName, emailAddress, contactNo, message, errors } = this.state;
    const script = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Bengaluru, Karnataka",
        postalCode: "",
        streetAddress: "",
      },
      url: "dfd.com/ff",
      image: ["https://example.com/photos/1x1/photo.jpg"],
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "",
          contactType: "customer service",
        },
      ],
      email: "contact@sample.com",
      sameAs: ["facebook.com/xxx", "twitter.com/xxx", ""],
    };
    return (
      <>
        {this.props.show && <MetaComponent script={script} />}
        <Modal
          size="l"
          show={this.props.show}
          onHide={this.handleClose}
          dialogClassName="modal-90w"
          backdrop="static"
          keyboard={false}
          className="contactForm"
        >
          <Modal.Header closeButton>
            <Modal.Title as="h2" style={{ paddingLeft: 20 }}>
              Contact Us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form name="contactUs" onSubmit={this.handleSubmit} method="post" netlify="true">
                <input type="hidden" name="form-name" value="contactUs" />
                <Row>
                  <FormGroup as={Col} md="12" controlId="fullName">
                    <label
                      className="is-cp-label-text modal-label requiredField"
                      htmlFor="fullName"
                    >
                      Full Name
                    </label>
                    <FormControl
                      name="fullName"
                      type="text"
                      id="fullName"
                      className="modal-input"
                      onChange={this.handleInputChange}
                      value={fullName}
                      isInvalid={!!errors.fullName}
                    />
                    {errors.fullName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.fullName}
                      </Form.Control.Feedback>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup as={Col} md="12" controlId="emailAddress">
                    <label className="modal-label is-cp-label-text" htmlFor="emailAddress">
                      Mail Id
                    </label>
                    <FormControl
                      name="emailAddress"
                      type="email"
                      id="emailAddress"
                      className="modal-input"
                      onChange={this.handleInputChange}
                      value={emailAddress}
                      isInvalid={!!errors.emailAddress || !!errors.phoneOrContact}
                    />
                    {(errors.emailAddress || errors.phoneOrContact) && (
                      <Form.Control.Feedback type="invalid">
                        {errors.emailAddress || errors.phoneOrContact}
                      </Form.Control.Feedback>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup as={Col} md="12" controlId="contactNo">
                    <label className="modal-label is-cp-label-text " htmlFor="contactNo">
                      Contact No
                    </label>
                    <FormControl
                      name="contactNo"
                      id="contactNo"
                      className="modal-input"
                      onChange={this.handleInputChange}
                      value={contactNo}
                      isInvalid={!!errors.contactNo || !!errors.phoneOrContact}
                    />
                    {(errors.contactNo || errors.phoneOrContact) && (
                      <Form.Control.Feedback type="invalid">
                        {errors.contactNo || errors.phoneOrContact}
                      </Form.Control.Feedback>
                    )}
                  </FormGroup>
                </Row>
                <Row className="mb-30">
                  <FormGroup as={Col} md="12" controlId="contactFor">
                    <label className="modal-label is-cp-label-text requiredField">
                      Contact For
                    </label>
                    <Col md={12} style={{ display: 'flex' }}>
                      <Col md={4} >
                        <Form.Check
                          type="radio"
                          label="Business"
                          name="contactFor"
                          id="radio1"
                          value="Business"
                          onChange={this.handleInputChange}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Check
                          type="radio"
                          label="Job"
                          name="contactFor"
                          id="radio2"
                          value="Job"
                          onChange={this.handleInputChange}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Check
                          type="radio"
                          label="Other"
                          name="contactFor"
                          id="radio3"
                          value="Other"
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Col>
                    <Col md={12} style={{ padding: '0.5rem 0' }}>
                      {errors.contactFor && (
                        <div className="invalid-feedback" style={{ display: "block" }}>
                          {errors.contactFor}
                        </div>
                      )}
                    </Col>
                  </FormGroup>

                </Row>
                <Row>
                  <FormGroup as={Col} md="12" controlId="message">
                    <label
                      className="modal-label is-cp-label-text requiredField"
                      htmlFor="message"
                    >
                      Leave a message
                    </label>
                    <FormControl
                      name="message"
                      id="message"
                      className="modal-textarea"
                      as="textarea"
                      rows="8"
                      onChange={this.handleInputChange}
                      value={message}
                      isInvalid={!!errors.message}
                    />
                    {errors.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    )}
                  </FormGroup>
                </Row>
                <div className="text-center">
                  <button
                    type="submit"
                    style={{
                      borderRadius: "30px",
                      border: "1px solid gray",
                      background: "#fff",
                      width: "14rem",
                      height: "3rem",
                      backgroundColor: "#27323d",
                      color: "white",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
