export const MAIN_LOGO = "/images/main-logo.svg";
export const HEADER_IMAGE = "/images/header-image.svg";
export const BANNER_IMAGE = "/images/banner-image.svg";
export const STORAGE_IMAGE = "/images/storage.svg";
export const OUR_PRODUCT = "/images/our-product.png";
export const FOOTER_LOGO = "/images/Footer_Logo.svg";

export const LOCATION = "/images/location.png";

export const AI = "/images/ai.svg";
export const REACT_LOGO = "/images/react-logo.svg";
export const NODE_LOGO = "/images/node-logo.svg";
export const DJANGO = "/images/django.svg";
export const AWS = "/images/aws.svg";
export const WEBPACK = "/images/webpack.svg";
export const FIGMA = "/images/Figma.svg";
export const ELASTIC_SEARCH = "/images/elasticsearch.svg";
export const JAVASCRIPT = "/images/javascript.svg";
export const REACT_NATIVE = "/images/react-native.svg";
export const PYTHON = "/images/python.svg";
export const CELERY = "/images/celery.svg";
export const MATERIAL_UI = "/images/material-ui.svg";
export const ICO_MOON = "/images/ico-moon.svg";
export const POSTGRE_SQL = "/images/postgre-SQL.svg";
export const REDUX = "/images/redux.svg";
export const WEB_SOCKETS = "/images/web-sockets.svg";
export const NETLIFY = "/images/netlify.svg";
export const REDIS = "/images/redis.svg";
export const GATSBY = "/images/gatsby.svg";

export const DEVELOPING = "/images/Icon_Developing.svg";
export const PROCESS_WE_FOLLOW = "/images/process-we-follow.svg";
export const OUR_TEAM = "/images/our-team.svg";
export const PRODUCT_DESIGN = "/images/product-design.svg";

export const BRAINSTORM = "/images/brainstorming.svg";
export const TEAM = "/images/team.svg";
export const REFUND = "/images/refund.svg";
export const GROUP = "/images/Group.svg";

export const FACEBOOK = "/images/facebook.svg";
export const FACEBOOK_DARK = "/images/facebook-dark.svg";
export const INSTAGRAM = "/images/instagram-logo.svg";
export const INSTAGRAM_DARK = "/images/instagram-logo-dark.svg";
export const LINKEDIN = "/images/linkedin.svg";
export const LINKEDIN_DARK = "/images/linkedin-dark.svg";
export const TWITTER = "/images/twitter.svg";
export const TWITTER_DARK = "/images/twitter-dark.svg";

export const INSTAGRAM_HOVER = "/images/instagram-on-hoverwebsite-icons.svg";
export const LINKEDIN_HOVER = "/images/linkedin-on-hoverwebsite-icons.svg";
export const TWITTER_HOVER = "/images/twitter-on-hoverwebsite-icons.svg";
export const FACEBOOK_HOVER = "/images/facebook-on-hoverwebsite-icons.svg";

export const INSTAGRAM_HEADER = "/images/instagramwebsite-icons.svg";
export const LINKEDIN_HEADER = "/images/linkedinwebsite-icons.svg";
export const TWITTER_HEADER = "/images/twitterwebsite-icons.svg";
export const FACEBOOK_HEADER = "/images/facebookwebsite-icons.svg";

export const MEDIUM = "/images/medium.svg";
export const MEDIUM_DARK = "/images/medium-dark.svg";

export const MENU_ICON = "/images/menu-icon.png";

export const LINKEDIN_ICON = "/images/linkedin.png";

export const CARETDOWN = "/images/caret-down.png";
export const CARETUP = "/images/caret-up.png";

export const Sanghmitra_PROFILE = "/images/reviews/Sanghmitra.png";
export const Vinay_PROFILE = "/images/reviews/Vinay.png";
export const Anitha_PROFILE = "/images/reviews/Anitha.png";
export const Aishwary_PROFILE = "/images/reviews/Aishwary.png";
export const Sagar_PROFILE = "/images/reviews/Sagar.png";
export const Sukhjeet_PROFILE = "/images/reviews/Sukhjeet.png";
export const Prathik_PROFILE = "/images/reviews/Prathik.png";
export const Vivek_PROFILE = "/images/reviews/Vivek.png";
export const Sonam_PROFILE = "/images/reviews/Sonam.png";
export const Preetha_PROFILE = "/images/reviews/Preetha.png";
export const Sampath_PROFILE = "/images/reviews/Sampath.png";
export const Pramay_PROFILE = "/images/reviews/Pramay.png";
export const Yash_PROFILE = "/images/reviews/Yash.png";
export const Bala_PROFILE = "/images/reviews/Bala.png";
export const Rachet_PROFILE = "/images/reviews/Rachet.png";
export const Hemalatha_Profile = "/images/reviews/Hemalatha.png";
export const Anusha_Profile = "/images/reviews/Anusha.png";
export const Mukul_Profile = "/images/reviews/Mukul.png";
export const Priya_Profile = "/images/reviews/Priya.png";

export const heading_section = "/images/career-imgs/head_section.png";

export const sub_section_1a = "/images/career-imgs/position_section1a.png";
export const sub_section_1b = "/images/career-imgs/position_section1b.png";
export const sub_section_1c = "/images/career-imgs/position_section1c.png";

export const sub_section_2a = "/images/career-imgs/position_section2a.png";

export const sub_section_3a = "/images/career-imgs/position_section3a.png";
export const sub_section_3b = "/images/career-imgs/position_section3b.png";
export const sub_section_3c = "/images/career-imgs/position_section3c.png";

export const amico = "/images/career-imgs/amico.png";
export const cuate = "/images/career-imgs/cuate.png";

// D:\Codeparva\codeparva-website-react\public\images\reviews\Aishwary.png

// D:\Codeparva\codeparva-website-react\src\Pages\CareerPage\career-imgs\3rafiki.png

// public\images\reviews\Aishwary.png    -> /images/reviews/
// src\Pages\CareerPage\career-imgs\3rafiki.png   ->

export const locationIMG = "/images/career-imgs/location.svg";
export const tech = "/images/career-imgs/tech.svg";

export const soft = "/images/career-imgs/soft.svg";

export const experience = "/images/career-imgs/experience.svg";

export const ctc = "/images/career-imgs/ctc.svg";

export const UP = "/images/career-imgs/arrow-up.svg";

export const DOWN = "/images/career-imgs/arrow-down.svg";

export const MENU = "/images/career-imgs/breadCrumb.svg";
export const LOCATION_LIGHT = "/images/career-imgs/location_light.svg";
export const PERIOD = "/images/career-imgs/period.svg";
export const YOUNG = "/images/career-imgs/undraw_young_and_happy_hfpe.svg";
export const RIGHT = "/images/career-imgs/right.svg";
export const RIGHT_DARK = "/images/career-imgs/right-dark.svg";

export const HUMAN_CONNECT = "/images/career-imgs/1.svg";
export const INTRO_CALL = "/images/career-imgs/2.svg";
export const PROBLEM_SOLVING = "/images/career-imgs/3.svg";
export const DESIGN_ROUND = "/images/career-imgs/4.svg";
export const OPEN_CONVO = "/images/career-imgs/5.svg";
export const DEV = " /images/career-imgs/DEVELOPMENT.svg";
export const DESIGN = "/images/career-imgs/DESIGN.svg";
export const PEOPLE = "/images/career-imgs/PEOPLE_OPEARTIONS.svg";
export const CAREER_JUMBOTRON = "/images/career-imgs/amico.svg";
export const DEV_CATEGORY = "/images/career-imgs/Developer.svg";
export const DESIGN_CATEGORY = "/images/career-imgs/Designer.svg";
export const PEOPLE_CATEGORY = "/images/career-imgs/pana.svg";

export const SDET = "/images/career-imgs/SDET.svg";
export const SDET_JD = "/images/career-imgs/SDET_JD.svg";
