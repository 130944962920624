import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import * as img from "../../constants/images";
export class NavBar extends Component {
  state = {
    tabs: [
      { name: "Home", page: "/" },
      { name: "Careers", page: "/career" },
      // { name: "Contact Us", page: null },
      { name: "Blogs", page: "https://blogs.codeparva.com" },
    ],
    menuDrawer: false,
  };
  menuDrawer = () => {
    this.setState({
      menuDrawer: !this.state.menuDrawer,
    });
  };

  render() {
    if (this.props.isMobile) {
      return (
        <div className="navBar py-3 w-100 align-items-center ">
          <div
            className="container w-100 d-flex align-items-center justify-content-between"
            style={{ position: "relative" }}
          >
            <Nav.Link href="/" className="p-0">
              <img className="navIcon" src={img.MAIN_LOGO} alt="img" />
            </Nav.Link>
            <img
              onClick={this.menuDrawer}
              className="menuIcon"
              src={img.MENU}
              alt="img"
            />
          </div>
          {this.state.menuDrawer ? (
            <div
              className="menuDrawer pl-4"
              style={{ background: "#fff", zIndex: "20", position: "absolute" }}
            >
              {this.state.tabs.map((res, index) => {
                return (
                  <div className="menuPadding w-100" key={index}>
                    <Nav.Link
                      className="tabText mobitTabText m-0 p-0"
                      target={res.name === "Blogs" ? "_blank" : "_self"}
                      onClick={
                        res.name === "Contact Us"
                          ? this.props.handleModal
                          : null
                      }
                      href={res.page}
                      style={{ fontSize: "0.8rem" }}
                    >
                      {res.name}
                    </Nav.Link>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="navBar">
          <div
            className="container d-flex justify-content-between align-items-center"
            style={{ textAlign: "center" }}
          >
            <div className="p-0 text-left">
              <Nav.Link href="/" className="p-0">
                <img className="navIcon" src={img.MAIN_LOGO} alt="img" />
              </Nav.Link>
            </div>
            <div
              className="p-0 d-flex justify-content-center navBarCustom"
              style={{ justifyContent: "flex-center" }}
            >
              {/* <div className="col-2 d-flex justify-content p-0"></div> */}
              {this.state.tabs.map((item, id) => {
                return (
                  <Nav
                    className="nav-items  d-flex justify-content p-0"
                    key={id}
                    activeKey={
                      window.location.pathname === item.page && item.page
                    }
                  >
                    <Nav.Link
                      className="tabText m-0 is-cp-body-text-1"
                      target={item.name === "Blogs" ? "_blank" : "_self"}
                      onClick={
                        item.name === "Contact Us"
                          ? this.props.handleModal
                          : null
                      }
                      href={item.page}
                    >
                      {item.name}
                    </Nav.Link>
                  </Nav>
                );
              })}
            </div>
            <div className="p-0 text-right">
              <a
                href="https://www.linkedin.com/company/codeparva/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseOver={() => this.setState({ linkedin: true })}
                onMouseOut={() => this.setState({ linkedin: false })}
              >
                {this.state.linkedin ? (
                  <img
                    className="icon-nav pr-4"
                    style={{ height: "21px" }}
                    src={img.LINKEDIN_HOVER}
                    alt="this img is link to linkedin account of codeparva"
                  />
                ) : (
                  <img
                    className="icon-nav pr-4"
                    style={{ height: "21px" }}
                    src={img.LINKEDIN_HEADER}
                    alt="this img is link to linkedin account of codeparva"
                  />
                )}
              </a>
              <a
                href="https://www.instagram.com/codeparvatechnologies/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseOver={() => this.setState({ instagram: true })}
                onMouseOut={() => this.setState({ instagram: false })}
              >
                {this.state.instagram ? (
                  <img
                    className="pr-4"
                    style={{ height: "21px" }}
                    src={img.INSTAGRAM_HOVER}
                    alt="this img is link to instagram account of codeparva"
                  />
                ) : (
                  <img
                    className="pr-4"
                    style={{ height: "21px" }}
                    src={img.INSTAGRAM_HEADER}
                    alt="this img is link to instagram account of codeparva"
                  />
                )}
              </a>
              <a
                href="https://www.facebook.com/codeparvatechnologies/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseOver={() => this.setState({ facebook: true })}
                onMouseOut={() => this.setState({ facebook: false })}
              >
                {this.state.facebook ? (
                  <img
                    className="pr-4"
                    style={{ height: "21px" }}
                    src={img.FACEBOOK_HOVER}
                    alt="this img is link to facebook account of codeparva"
                  />
                ) : (
                  <img
                    className="pr-4"
                    style={{ height: "21px" }}
                    src={img.FACEBOOK_HEADER}
                    alt="this img is link to facebook account of codeparva"
                  />
                )}
              </a>
              <a
                href="https://twitter.com/codeparva/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseOver={() => this.setState({ twitter: true })}
                onMouseOut={() => this.setState({ twitter: false })}
              >
                {this.state.twitter ? (
                  <img
                    className="pr-4"
                    style={{ height: "21px" }}
                    src={img.TWITTER_HOVER}
                    alt="this img is link to twitter account of codeparva"
                  />
                ) : (
                  <img
                    className="pr-4"
                    style={{ height: "21px" }}
                    src={img.TWITTER_HEADER}
                    alt="this img is link to twitter account of codeparva"
                  />
                )}
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}
