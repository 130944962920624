import * as img from "../../constants/images";

export const aboutUs = `We 'Code' to develop Operations & Management software, which impatcs both large Corporations as well as mon-and-pop business. Our core speciality lies in Self Storage
Management, Property Management, Payment Processing and modern B2C Website Development Platforms.`;

export const h4 = `From Boston to Bengaluru - CodeParva runs Business`;

export const scriptSSE = [
  {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: "Senior Software Engineer - I",
    url: "https://codeparva.com/jobs/bengaluru/open-positions/senior-software-engineer",
    description: `A top-notch platform where you can leverage your skills to build full features and modules in <b>Django and ReactJs</b>, using technologies like 
    <li>Elasticsearch<li>
    <li>Redux</li> 
    <li>WebSockets</li> 
    <li>MaterialUI</li> 
    <li>Formik</li> 
    <li>RabbitMQ</li> 
    <li>Celery</li> 
    <li>Redis</li>
    when necessary A team which is eager to learn from you and to mentor you A clean slate for new ideas & methodologies Competitive salaries for your contributions`,

    hiringOrganization: {
      "@type": "Organization",
      name: "CodeParva Technologies Pvt. Ltd.",
      sameAs: "www.codeparva.com",
      logo: `https://codeparva.com/${img.MAIN_LOGO}`,
    },
    industry: "Software Company",
    employmentType: "FULL_TIME",
    workHours: "09.00-18.00",
    datePosted: "2022-12-14",
    validThrough: "2023-01-14",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "No. #45, Second Floor, 24th Main Road, J.P. Nagar 7th Phase.",
        addressLocality: "bengaluru",
        addressRegion: "karnataka",
        postalCode: "560078",
        addressCountry: "IN",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "INR",
      value: {
        "@type": "QuantitativeValue",
        minValue: 1000000,
        maxValue: 1500000,
        unitText: "YEAR",
      },
    },
    responsibilities:
      "Ability to deliver production quality code in a timely manner. Ability to design, develop and own modules/features of the product.Ability to work independently. Ability to mentor developers and handle code reviews.",
    skills:
      "2+ years of relevant experience in Web development, ReactJs/Django, SQL Database, Git. Experience in any of these: Redis, Celery, Elastic search, AWS.",
    qualifications: "Any Graduate ",
    educationRequirements: {
      "@type": "EducationalOccupationalCredential",
      credentialCategory: "bachelor degree",
    },
    experienceRequirements: {
      "@type": "OccupationalExperienceRequirements",
      monthsOfExperience: "24",
      description:
        "Experienced web developer with the ability to write production quality code in python or javascript based technologies (Preferably ReactJs, Django). ",
    },
    experienceInPlaceOfEducation: true,
    jobBenefits:
      " Medical Insurance, Travel Reimbursement, Food Reimbursement, Maternity and paternity leave, Internet Reimbursement, Relocation Reimbursement ",
    totalJobOpenings: "3",
    "directApply ": true,
    jobImmediateStart: true,
  },
];

export const scriptSE = [
  {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: "Software Engineer",
    url: "https://codeparva.com/jobs/bengaluru/open-positions/full-stack-developer",
    description: `A top-notch platform where you can leverage your skills to build full features and modules in <b>Django and ReactJs</b>, using technologies like 
    <li>Elasticsearch<li>
    <li>Redux</li> 
    <li>WebSockets</li> 
    <li>MaterialUI</li> 
    <li>Formik</li> 
    <li>RabbitMQ</li> 
    <li>Celery</li> 
    <li>Redis</li>
    when necessary A team which is eager to learn from you and to mentor you A clean slate for new ideas & methodologies Competitive salaries for your contributions`,
    hiringOrganization: {
      "@type": "Organization",
      name: "CodeParva Technologies Pvt. Ltd.",
      sameAs: "www.codeparva.com",
      logo: `https://codeparva.com/${img.MAIN_LOGO}`,
    },
    industry: "Software Company",
    employmentType: "FULL_TIME",
    workHours: "09.00-18.00",
    datePosted: "2022-12-14",
    validThrough: "2023-01-14",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "No. #45, Second Floor, 24th Main Road, J.P. Nagar 7th Phase.",
        addressLocality: "bengaluru",
        addressRegion: "karnataka",
        postalCode: "560078",
        addressCountry: "IN",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "INR",
      value: {
        "@type": "QuantitativeValue",
        minValue: 600000,
        maxValue: 1000000,
        unitText: "YEAR",
      },
    },
    responsibilities: `Ability to deliver production quality code in a timely manner.
      Ability to design, develop and own modules/features of the product.`,
    skills:
      "1+ year of relevant experience in Web development, ReactJs/Django, SQL Database, Git. Experience in any of these: Redis, Celery, Elastic search, AWS.",
    qualifications: " Any Graduates",
    educationRequirements: {
      "@type": "EducationalOccupationalCredential",
      credentialCategory: "bachelor degree",
    },
    experienceRequirements: {
      "@type": "OccupationalExperienceRequirements",
      monthsOfExperience: "12",
      description:
        "Experienced web developer with the ability to write production quality code in python or javascript based technologies (Preferably ReactJs, Django).",
    },
    experienceInPlaceOfEducation: true,

    jobBenefits:
      "Medical Insurance, Travel Reimbursement, Food Reimbursement, Maternity and paternity leave, Internet Reimbursement, Relocation Reimbursement",
    totalJobOpenings: "5",
    "directApply ": true,
    jobImmediateStart: true,
  },
];

export const scriptUIUX = [
  {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: "UI/UX Designer",
    url: "https://codeparva.com/jobs/bengaluru/open-positions/ui-ux-designer",
    description: `We are looking for a <b>UI/UX designer </b> who has experience in: 
    <li>Typography</li> 
    <li>Colour theory</li> 
    <li>Different platforms such as iOS, Android and Web applications</li>
    <li>Layout Design and Spacing methods</li> 
    <li>Iconography</li> 
    <li>Icomoon </li> 
    <li> Driving a user experience from start to finish that delivers results and empathizes with users</li> 
    <li>HTML</li> 
    <li>CSS</li> 
    <li>Figma Experienced in B2C products</li> 
    <li>Fluency in a responsive, intuitive</li> 
    <li>interactive and consistent design</li> 
    <li>Ability to modify already existing solutions and the ability to design wireframes from scratch</li> 
    <li>Review and test screens to ensure that your vision is materialized</li>`,
    hiringOrganization: {
      "@type": "Organization",
      name: "CodeParva Technologies Pvt. Ltd.",
      sameAs: "www.codeparva.com",
      logo: `https://codeparva.com/${img.MAIN_LOGO}`,
    },
    industry: "Software Company",
    employmentType: "FULL_TIME",
    workHours: "09.00-18.00",
    datePosted: "2022-12-14",
    validThrough: "2023-01-14",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "No. #45, Second Floor, 24th Main Road, J.P. Nagar 7th Phase.",
        addressLocality: "bengaluru",
        addressRegion: "karnataka",
        postalCode: "560078",
        addressCountry: "IN",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "INR",
      value: {
        "@type": "QuantitativeValue",
        minValue: 450000,
        maxValue: 700000,
        unitText: "YEAR",
      },
    },
    responsibilities: `Ability to modify already existing solutions and the ability to design wireframes from scratch, Review and test screens to ensure that your vision is materialized.`,
    skills:
      "Typography,  Colour theory, Layout Design and Spacing methods, Iconography, Icomoon, HTML, CSS, Figma",
    qualifications: " Any Graduates",
    educationRequirements: {
      "@type": "EducationalOccupationalCredential",
      credentialCategory: "bachelor degree",
    },
    experienceRequirements: {
      "@type": "OccupationalExperienceRequirements",
      monthsOfExperience: "24",
      description:
        "Experienced UI/UX designer with a good understanding of material UI design principles, B2B and B2C product designing",
    },
    experienceInPlaceOfEducation: true,

    jobBenefits:
      "Medical Insurance, Travel Reimbursement, Food Reimbursement, Maternity and paternity leave, Internet Reimbursement, Relocation Reimbursement",
    totalJobOpenings: "1",
    "directApply ": true,
    jobImmediateStart: true,
  },
];

export const Jumbotron_Description = `Experienced web developer with the ability to write production quality code in python or javascript based technologies`;

export const SE_Title = [
  {
    Title: "Job openings | software Engineer ( Full Stack )",
  },
];

export const SSE_Title = [
  {
    Title: "Job openings | Senior software engineer",
  },
];

export const UIUX_Title = [
  {
    Title: "Job openings | UI/UX Designer",
  },
];

export const SE_Description = [
  {
    property: "description",
    content:
      "Experienced web developer with the ability to write production quality code in python or javascript based technologies (Preferably ReactJs, Django).",
  },
];

export const SSE_Description = [
  {
    property: "description",
    content:
      "Experienced web developer with the ability to write production quality code in python or javascript based technologies (Preferably ReactJs, Django).",
  },
];

export const UIUX_Description = [
  {
    property: "description",
    content:
      "Experienced UI/UX designer with a good understanding of material UI design principles, B2B and B2C product designing.",
  },
];

export const ogGraphSSE = [
  // {
  //   property: "description",
  //   content:
  //     "Experienced web developer with the ability to write production quality code in python or javascript based technologies (Preferably ReactJs, Django).",
  // },
  {
    property: `og:description`,
    content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    property: `og:title`,
    content: `Job openings | Senior software engineer`,
  },
  {
    property: `og:site_name`,
    content: `CodeParva Technologies Pvt. Ltd.`,
  },
  {
    property: `og:url`,
    content: `${process.env.REACT_APP_URL}/open-positions/senior-software-engineer`,
  },
  {
    property: `og:image`,
    content: "https://codeparva.com/images/main-logo.svg",
  },
];

export const twitterCardSSE = [
  {
    name: `twitter:site`,
    content: `CodeParva Technologies Pvt. Ltd.`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: `@codeparva`,
  },
  {
    name: `twitter:title`,
    content: `Job openings | Senior software engineer`,
  },
  {
    name: `twitter:description`,
    content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`,
  },
  {
    name: `twitter:image`,
    content: "https://codeparva.com/images/main-logo.svg",
  },
];

export const ogGraphSE = [
  // {
  //   property: "description",
  //   content:
  //     "Experienced web developer with the ability to write production quality code in python or javascript based technologies (Preferably ReactJs, Django).",
  // },
  {
    property: `og:description`,
    content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    property: `og:title`,
    content: `Job openings | software Engineer ( Full Stack )`,
  },
  {
    property: `og:site_name`,
    content: `CodeParva Technologies Pvt. Ltd.`,
  },
  {
    property: `og:url`,
    content: `${process.env.REACT_APP_URL}/open-positions/full-stack-developer`,
  },
  {
    property: `og:image`,
    content: "https://codeparva.com/images/main-logo.svg",
  },
];

export const twitterCardSE = [
  {
    name: `twitter:site`,
    content: `CodeParva Technologies Pvt. Ltd.`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: `@codeparva`,
  },
  {
    name: `twitter:title`,
    content: `Job openings | software Engineer ( Full Stack )`,
  },
  {
    name: `twitter:description`,
    content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`,
  },
  {
    name: `twitter:image`,
    content: "https://codeparva.com/images/main-logo.svg",
  },
];

export const ogGrapUIUX = [
  // {
  //   property: "description",
  //   content:
  //     "Experienced UI/UX designer with a good understanding of material UI design principles, B2B and B2C product designing",
  // },
  {
    property: `og:description`,
    content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`,
  },
  {
    property: "description",
    content:
      "Experienced UI/UX designer with a good understanding of material UI design principles, B2B and B2C product designing.",
  },
  {
    property: `og:description`,
    content: `Experienced UI/UX designer with a good understanding of material UI design principles, B2B and B2C product designing.`,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    property: `og:title`,
    content: `Job openings | UI/UX Designer`,
  },
  {
    property: `og:site_name`,
    content: `CodeParva Technologies Pvt. Ltd.`,
  },
  {
    property: `og:url`,
    content: `${process.env.REACT_APP_URL}/open-positions/ui-ux-designer`,
  },
  {
    property: `og:image`,
    content: "https://codeparva.com/images/main-logo.svg",
  },
];

export const twitterCardUIUX = [
  {
    name: `twitter:site`,
    content: `CodeParva Technologies Pvt. Ltd.`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: `@codeparva`,
  },
  {
    name: `twitter:title`,
    content: `Job openings | UI/UX Designer`,
  },
  {
    name: `twitter:description`,
    content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.Experienced UI/UX designer with a good understanding of material UI design principles, B2B and B2C product designing.`,
  },
  {
    name: `twitter:image`,
    content: "https://codeparva.com/images/main-logo.svg",
  },
];
