import { Button } from "react-bootstrap";
import React from "react";

export const CTA = (props) => {
  const { children, className, href, icon, disabled, onClick } = props;
  return (
    <Button href={href} className={`CTA ${className}`} disabled={disabled} onClick={onClick}>
      {children}
      {icon.icon && (
        <span className="IconPadding">
          <img src={icon.icon} alt={icon.alt} />
        </span>
      )}
    </Button>
  );
};
