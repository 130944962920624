import React, { Component } from "react";
import { Helmet } from "react-helmet";
export class MetaComponent extends Component {
  render() {
    return (
      <Helmet>
        {this.props.script && (
          <script type="application/ld+json">
            {JSON.stringify(this.props.script)}
          </script>
        )}
        {this.props.Title &&
          this.props.Title.map((Title, index) => (
            <title key={index}>{Title.Title}</title>
          ))}

        {this.props.Description &&
          this.props.Description.map((Description, index) => (
            <meta
              key={index}
              name={Description.property}
              content={Description.content}
            ></meta>
          ))}

        {this.props.ogGraph &&
          this.props.ogGraph.map((ogGraph, index) => (
            <meta
              key={index}
              name={ogGraph.property}
              content={ogGraph.content}
            ></meta>
          ))}
        {this.props.twitterCard &&
          this.props.twitterCard.map((twitterCard, index) => (
            <meta
              name={twitterCard.name}
              content={twitterCard.content}
              key={index}
            ></meta>
          ))}
      </Helmet>
    );
  }
}
