import React from "react";
// import { Link } from "react-router-dom";
// import LazyLoad from "react-lazyload";
import { Redirect, Route, useLocation } from "react-router-dom";
import { DesignationPage } from "../../Pages/DesignationPage/designationPage";

export const NotFound = ({ users }) => {
  let location = useLocation();
  if (
    location.pathname === "/jobs/bengaluru/open-positions/full-stack-developer"
  ) {
    return (
      <Route
        to="/jobs/bengaluru/open-positions/full-stack-developer"
        component={DesignationPage}
      />
    );
  } else if (
    location.pathname ===
    "/jobs/bengaluru/open-positions/senior-software-engineer"
  ) {
    return (
      <Route
        to="/jobs/bengaluru/open-positions/senior-software-engineer"
        component={DesignationPage}
      />
    );
  } else {
    if (location.pathname === "/jobs/bengaluru/open-positions/ui-ux-designer") {
      return (
        <Route
          to="/jobs/bengaluru/open-positions/ui-ux-designer"
          component={DesignationPage}
        />
      );
    } else {
      return <Redirect to="/" />;
    }
  }

  //will uncomment when we will implement custom error page//
  /*
  <div className="wrapper">
      <img src="https://i.imgur.com/yW2W9SC.png" className="ghostImage" />
    </LazyLoad>
    <br />

    <div style={{ textAlign: "center" }}>
      <Link to="/" className="linktoHome">
        Click here to go back to Home!
      </Link>
    </div>

    <h3 className="headerContent">OOPS! This Page is Broken!</h3>
    <div className="errorContent">
      This mug was a family heirloom. Of your neighbor. Your neighbor always
      loved the color, shape, and quantity of coffee held by this mug. But your
      neighbor moved out and left it on their porch, no explanation, no repair
      materials, no nothing. So you have this broken mug.
    </div>
    <br />
    <br />
    <p
      style={{
        color: "#5f749c",
        lineHeight: 2,
        fontSize: "small",
        margin: "0px",
      }}
    >
      © 2021 by CodeParva Technologies Pvt Ltd. All rights reserved.
    </p>
  </div>*/
};
