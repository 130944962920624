import React from "react";
import { Button } from "react-bootstrap";
import * as img from "../../constants/images";

export const WorkWithUsHeaderSection2 = (props) => {
  if (props.isMobile) {
    return (
      <div className="d-flex headerSection align-items-center ">
        <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
          <div className="w-100 justify-content-center d-flex">
            <p
              style={{ color: "#212529", lineHeight: "1" }}
              className="headerTitle"
            >
              Perks @ CodeParva
            </p>
          </div>
          <ul className="pl-20 max-width-90">
            <li className="bannerSubTitle">Fun set of people to work with. </li>
            <li className="bannerSubTitle">
              We have a fully stocked pantry with munchies and knick knacks that
              our team loves to chew on while chewing on interesting problems.{" "}
            </li>
            <li className="bannerSubTitle">
              We work hard and party hard - go on regular team outings, go out
              for drinks (on the company when we have visitors). Our purse
              strings don't run too tight for those we call our own - we pay
              competitive salaries.{" "}
            </li>
            <li className="bannerSubTitle">
              We have food and fuel reimbursements upto Rs.1500 each, per month
              (over and above the CTC). Also we have a budget for courses for
              employees to take up to further their career interests.{" "}
            </li>
            <li className="bannerSubTitle">
              Equal maternity and paternity leaves.{" "}
            </li>
            <li className="bannerSubTitle">
              We don't let biases like gender, race, language, seniority cloud
              our decision making.{" "}
            </li>
          </ul>
          <div
            data-aos="fade-up"
            className="w-100 justify-content-center d-flex py-2"
          >
            <img
              style={{ zIndex: 10 }}
              className="bannerLogo"
              src={img.BANNER_IMAGE}
              alt="img"
            />
          </div>
          <div className="w-100 justify-content-center d-flex py-2">
            {props.isHome ? (
              <Button
                href="jobs/bengaluru/open-positions"
                style={{
                  background: "#27323d",
                  color: "white",
                  borderRadius: "30px",
                  border: "1px solid gray",
                  width: "20rem",
                  height: "3rem",
                  lineHeight: 2,
                }}
              >
                View Open Positions
              </Button>
            ) : (
              <Button
                href="jobs/bengaluru/open-positions"
                style={{
                  borderRadius: "30px",
                  border: "1px solid gray",
                  background: "#fff",
                  width: "20rem",
                  height: "3rem",
                  color: "black",
                  lineHeight: 2,
                }}
              >
                View Open Positions
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex headerSection align-items-center rightBackImage">
        <div className="col-8 p-0 h-100 d-flex justify-content-center flex-column">
          <div>
            <p
              style={{ color: "#212529", lineHeight: "1.5" }}
              className="headerTitle"
            >
              Perks @ CodeParva
            </p>
          </div>
          <div style={{ padding: "3rem 0rem" }}>
            <ul className="pl-20 max-width-90">
              <li className="bannerSubTitle">
                Fun set of people to work with.{" "}
              </li>
              <li className="bannerSubTitle">
                We have a fully stocked pantry with munchies and knick knacks
                that our team loves to chew on while chewing on interesting
                problems.{" "}
              </li>
              <li className="bannerSubTitle">
                We work hard and party hard - go on regular team outings, go out
                for drinks (on the company when we have visitors). Our purse
                strings don't run too tight for those we call our own - we pay
                competitive salaries.{" "}
              </li>
              <li className="bannerSubTitle">
                We have food and fuel reimbursements upto Rs.1500 each, per
                month (over and above the CTC). Also we have a budget for
                courses for employees to take up to further their career
                interests.{" "}
              </li>
              <li className="bannerSubTitle">
                Equal maternity and paternity leaves.{" "}
              </li>
              <li className="bannerSubTitle">
                We don't let biases like gender, race, language, seniority cloud
                our decision making.{" "}
              </li>
            </ul>
          </div>
          {props.isHome ? (
            <Button
              href="jobs/bengaluru/open-positions"
              style={{
                background: "#27323d",
                color: "white",
                borderRadius: "30px",
                border: "1px solid gray",
                width: "20rem",
                height: "3rem",
                lineHeight: 2,
              }}
            >
              View Open Positions
            </Button>
          ) : (
            <Button
              href="jobs/bengaluru/open-positions"
              style={{
                borderRadius: "30px",
                border: "1px solid gray",
                background: "#fff",
                width: "20rem",
                height: "3rem",
                color: "black",
                lineHeight: 2,
              }}
            >
              View Open Positions
            </Button>
          )}
        </div>
        <div
          data-aos="fade-up"
          className="col-4 d-flex justify-content-end px-0 py-3 h-100 "
        >
          <img
            style={{ zIndex: 10 }}
            className="bannerLogo"
            src={img.BANNER_IMAGE}
            alt="img"
          />
        </div>
      </div>
    );
  }
};
