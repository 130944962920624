import React, { Component } from "react";
import { Heading } from "../../Helpers/Heading/Heading";

import { patten, images, Mpatten } from "./pattern";
export class TechBox extends Component {
  state = {};
  render() {
    let i = -1;
    if (this.props.isMobile) {
      return (
        <div className="w-100">
          <div className="headerSection container">
            <div className="w-100 d-flex justify-content-center">
              <Heading RenderAs="h2">Technologies</Heading>
            </div>

            <div
              className="w-100 d-flex justify-content-center"
              style={{ padding: "1rem 0rem", textAlign: "center" }}
            >
              <p className="is-cp-body-text-1 m-0 ">
                The following are some of the technologies that help us build a
                complex, highly-configurable B2B product that needs to achieve
                performance and scalability
              </p>
            </div>
          </div>

          <div style={{ background: "#ECF8FB" }}>
            <div className="mt-4 blueSection w-100 flex-wrap d-flex ">
              {Mpatten.map((item, index) => {
                item === 1 && i++;
                return (
                  <div
                    data-aos="zoom-in-up"
                    className="d-flex justify-content-center techBoxPart"
                    style={{ marginTop: "-1.5%" }}
                    key={index}
                  >
                    {item === 1 ? (
                      <div className="techBox">
                        <img src={images[i]} alt="img" />
                      </div>
                    ) : null}
                  </div>
                );
              })}
              <div
                style={{ padding: "1rem 0rem" }}
                className="d-flex w-100 justify-content-center"
              >
                {/* <button style={{ borderRadius: '30px', border: "1px solid black", background: "transparent", width: '22rem', height: "3rem" }}>More Details about tech we use</button> */}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-100 ">
          <div className="headerSection container">
            <Heading RenderAs="h2">Technologies</Heading>
            <div style={{ padding: "2rem 0rem" }}>
              <p className="is-cp-body-text-1 m-0 ">
                The following are some of the technologies that help us build a
                complex, highly-configurable B2B product that needs to achieve
                performance and scalability
              </p>
            </div>
          </div>

          <div style={{ background: "#ECF8FB" }}>
            <div className="mt-5 blueSection w-100 flex-wrap d-flex ">
              {patten.map((item, index) => {
                item === 1 && i++;
                return (
                  <div
                    key={index}
                    data-aos="zoom-in-up"
                    className="d-flex justify-content-center techBoxPart"
                    style={{ marginTop: "-1.5%" }}
                  >
                    {item === 1 ? (
                      <div className="techBox">
                        <img src={images[i]} alt="img" />
                      </div>
                    ) : null}
                  </div>
                );
              })}
              <div
                style={{ padding: "2rem 0rem" }}
                className="d-flex w-100 justify-content-center"
              >
                {/* <button style={{ borderRadius: '30px', border: "1px solid black", background: "transparent", width: '22rem', height: "3rem" }}>More Details about tech we use</button> */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
