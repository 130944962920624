import React from "react";
import { Heading } from "../../Helpers/Heading/Heading";
import { CTA } from "../../Helpers/Button/Button";
import {
  tech,
  soft,
  ctc,
  experience,
  locationIMG,
} from "../../constants/images";
import { useHistory } from "react-router-dom";
import { tabletscreen } from "../../constants/screen";

const width = window.screen.width;

export const Card = ({ openCategory }) => {
  const history = useHistory();
  return openCategory.current_opening.length <= 0 ? (
    <div className="is-cp-body-text-1">
      Currently we are not doing any hiring for people operations. but we do
      hiring quite frequently , submit your resume & our HR team will get in
      touch with you if there will be any requirement.
    </div>
  ) : (
    openCategory.current_opening.map((position, index) => (
      <div className="openPositionCard " key={index}>
        <Heading
          RenderAs="h4"
          specialData={false}
          className="is-cp-background-primary is-cp-light-color py-3 px-4 mb-0 openPositionCardHeading"
          dataText={{ __html: position.designation }}
        ></Heading>
        <div className="pt-3 pb-2 px-4 d-flex flex-wrap">
          {position.location && (
            <div className="is-cp-body-text-2 col-md-4 col-12 px-0 is-flex-start">
              <img src={locationIMG} alt="location" />
              <span className="ml-2">{position.location}</span>
            </div>
          )}
          {position.experience && (
            <div
              className={`is-cp-body-text-2 col-md-4 col-12 px-0 experience is-flex-start ${
                width <= tabletscreen && "pt-4"
              }`}
            >
              <img src={experience} alt="experience" />
              <span className="ml-2">{position.experience}</span>
            </div>
          )}
          {position.ctc && (
            <div
              className={`is-cp-body-text-2 col-md-4 col-12 px-0 is-flex-start ${
                width <= tabletscreen && "pb-4"
              }`}
            >
              <img src={ctc} alt="ctc" />
              <span className="ml-2">{position.ctc}</span>
            </div>
          )}
        </div>

        {position.skills.map((skills, index) => (
          <div className="py-3 px-4 is-cp-body-text-2" key={index}>
            <div className="d-flex pb-2 ">
              <img
                src={skills.section.includes("Technical") ? tech : soft}
                alt="location"
              />
              <div className="ml-2">{skills.section}</div>
            </div>

            <>
              <span className="my-1">
                {skills.skills.map((item, index) => (
                  <span
                    className="badge badge-pill px-3 py-1 mr-3 my-1 is-background-primary is-cp-body-text-2 is-background"
                    key={index}
                  >
                    {item.data}
                    {item.required && (
                      <span className="is-required">&nbsp;*</span>
                    )}
                  </span>
                ))}
              </span>
              <span
                className="ViewMore"
                onClick={() => {
                  history.push({
                    pathname: `/jobs/bengaluru/open-positions/${position.slug}`,
                    state: {
                      image: position.image,
                      position: position,
                      clickedfrom: "viewdetails",
                    },
                  });
                }}
              >
                View More
              </span>
            </>
          </div>
        ))}
        <div className="py-3 px-4 text-left ">
          {position.cta.map((item, index) =>
            index % 2 === 0 ? (
              <CTA
                key={index}
                className="is-cp-secondary-small-button"
                icon={""}
                onClick={() => {
                  history.push({
                    pathname: `/jobs/bengaluru/open-positions/${position.slug}`,
                    state: {
                      image: openCategory.image,
                      position: position,
                      clickedfrom: "viewdetails",
                    },
                  });
                }}
              >
                {item.label}
              </CTA>
            ) : (
              <CTA
                key={index}
                className="is-cp-primary-small-button"
                icon={""}
                onClick={() => {
                  history.push({
                    pathname: `/jobs/bengaluru/open-positions/${position.slug}`,
                    state: {
                      image: openCategory.image,
                      position: position,
                      clickedfrom: "applynow",
                    },
                  });
                }}
              >
                {item.label}
              </CTA>
            )
          )}
        </div>
      </div>
    ))
  );
};
