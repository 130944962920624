import React from "react";
import { CTA } from "../../Helpers/Button/Button";
import { Heading } from "../../Helpers/Heading/Heading";
import * as img from "../../constants/images";

export const HeaderSection = (props) => {
  const showOpenPositionSection = JSON.parse(process.env.REACT_APP_HIRING).status || false
  if (props.isMobile) {
    return (
      <div className="d-flex container headerSection align-items-center ">
        <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
          <div className="w-100 justify-content-center d-flex">
            <Heading RenderAs="h1" color="" specialData={true}>
              THE ERA OF <span className="specialCharacter">CODE</span>
            </Heading>
          </div>

          <div
            style={{ padding: "0rem 0rem", textAlign: "center" }}
            className="w-100 justify-content-center d-flex"
          >
            <p className="is-cp-body-text-1 mb-0">
              The first Indian software development company that specializes in
              delivering high-quality integrated solutions for the Global self
              storage industry.
            </p>
          </div>
          <div
            data-aos="fade-up"
            className="w-100 justify-content-center d-flex py-2"
          >
            <img
              style={{ zIndex: 10 }}
              className="headerLogo"
              src={img.HEADER_IMAGE}
              alt="img"
            />
          </div>
          <div className="w-100 justify-content-center d-flex py-2">
            {showOpenPositionSection && <CTA
              className="is-cp-secondary-button"
              href="jobs/bengaluru/open-positions"
              icon={""}
            >
              View Open Positions
            </CTA>}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex container headerSection align-items-center rightBackImage">
        <div className="col-6 p-0 h-100 d-flex justify-content-center flex-column">
          <Heading RenderAs="h1" color="" specialData={true}>
            THE ERA OF <span className="specialCharacter">CODE</span>
          </Heading>

          <div style={{ padding: "2.5rem 0rem" }}>
            <p className="is-cp-body-text-1 mb-0">
              The first Indian software development company that specializes in
              delivering high-quality integrated solutions for the Global self
              storage industry.
            </p>
          </div>
          {showOpenPositionSection && <CTA
            href="jobs/bengaluru/open-positions"
            className="is-cp-secondary-button"
            icon={""}
          >
            View Open Positions
          </CTA>}
        </div>
        <div
          data-aos="fade-up"
          className="col-6 d-flex justify-content-end px-0 py-3 h-100 "
        >
          <img
            style={{ zIndex: 10 }}
            className="headerLogo"
            src={img.HEADER_IMAGE}
            alt="img"
          />
        </div>
      </div>
    );
  }
};
