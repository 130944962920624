import { useEffect, useState } from "react";
import axios from "axios";

const useCustomHooks = () => {
  const [data, setData] = useState({ users: [], isFetching: false });

  useEffect(() => {
    const fetchUsers = async () => {
      setData({ users: [], isFetching: true });
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/teams`)
        .then((response) =>
          setData({ users: response.data, isFetching: false })
        )
        .catch((e) => {
          setData({ users: data.users, isFetching: false });
        });
    };
    fetchUsers();
  });

  return [data];
};

export default useCustomHooks;
