import React, { Component } from "react";
import { Heading } from "../../Helpers/Heading/Heading";

export class OurStorySection extends Component {
  readMore = () => {
    this.props.toggle();
  };

  render() {
    if (this.props.isMobile) {
      return (
        <div data-aos="zoom-in" className="d-flex headerSection container">
          <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
            <div className="w-100 justify-content-center d-flex">
              <Heading RenderAs="h2">Our Story</Heading>
            </div>
            <div
              className="w-100 justify-content-center d-flex flex-column align-items-center"
              style={{ padding: "1rem 0rem" }}
            >
              <p className="is-cp-body-text-1 mb-0">
                Tom Garden, started Syrasoft, with humble beginnings of working
                out of a friend's garage (offer him a few beers and he'll regale
                you with stories of software development practices of the yore -
                when floppy disks were physically mailed to provide software
                updates). Having built the company from ground up, he is the
                wise old mentor that has groomed Sampath, a former associate at
                Syrasoft. Having recognised the need for a daughter company to
                share the technical workload, Tom charged Sampath with the task
                of setting the operation up at Bengaluru. And thus, Code Parva
                was born - the first Indian company to foray in this domain.
                Although Syrasoft finances their operations, Code Parva is an
                organisation that controls its operations, product decisions and
                culture. Sampath (Director), Preetha (Product Manager), Anitha
                (CTO), the forerunners of the team, sincerely believe that a
                good culture is instrumental in building a team where every
                stakeholder benefits. They hope to facilitate an environment
                where communication is devoid of hesitation, where ideas,
                irrespective of the person it comes from, get evaluated in an
                unbiased manner, here technical excellence, loyalty and courage
                is rewarded. They also do acknowledge that they, being mere
                mortals, stumble at times in meeting these objectives. But they
                learn from their mistakes and never stop trying. With their
                enthusiasm coupled with a touch of idealism balanced by a
                healthy shot of cynicism, they hope to build a promising place
                to build a career.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div data-aos="zoom-in" className="d-flex headerSection container">
          <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
            <Heading RenderAs="h2">Our Story</Heading>

            <div style={{ padding: "4rem 0rem" }}>
              <p className="is-cp-body-text-1 mb-0">
                Tom Garden, started Syrasoft, with humble beginnings of working
                out of a friend's garage (offer him a few beers and he'll regale
                you with stories of software development practices of the yore -
                when floppy disks were physically mailed to provide software
                updates). Having built the company from ground up, he is the
                wise old mentor that has groomed Sampath, a former associate at
                Syrasoft. Having recognised the need for a daughter company to
                share the technical workload, Tom charged Sampath with the task
                of setting the operation up at Bengaluru. And thus, Code Parva
                was born - the first Indian company to foray in this domain.
                Although Syrasoft finances their operations, Code Parva is an
                organisation that controls its operations, product decisions and
                culture. Sampath (Director), Preetha (Product Manager), Anitha
                (CTO), the forerunners of the team, sincerely believe that a
                good culture is instrumental in building a team where every
                stakeholder benefits. They hope to facilitate an environment
                where communication is devoid of hesitation, where ideas,
                irrespective of the person it comes from, get evaluated in an
                unbiased manner, here technical excellence, loyalty and courage
                is rewarded. They also do acknowledge that they, being mere
                mortals, stumble at times in meeting these objectives. But they
                learn from their mistakes and never stop trying. With their
                enthusiasm coupled with a touch of idealism balanced by a
                healthy shot of cynicism, they hope to build a promising place
                to build a career.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}
