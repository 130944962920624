import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('Please provide your full name.'),
    emailAddress: Yup.string()
        .email('Please provide a valid email address.'),
    contactNo: Yup.string()
        .matches(/^\d{0}$|^\d{10}$/, 'Contact number must be exactly 10 digits long.'),
    contactFor: Yup.string()
        .required('Please select a contact reason.'),
    message: Yup.string()
        .required('Please leave a message.')
});
