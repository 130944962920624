import * as img from "../../constants/images";

export const careerPage_description = `Tread your fit and unveil your true potential.<br />
Scroll to explore our job openings, a workplace culture that encourages innovation and supports diversity and inclusion.`;

export const PageHeadingH1 = `Careers`;

// export const careerPara =
//   "Tread your fit and unveil your true potential.Scroll to explore our job openings, a workplace culture that encourages innovation and supports diversity and inclusion.";

export const careerPara = `Keep a lookout for new job openings.`;
export const headImg = img.CAREER_JUMBOTRON;

export const jobDescriptionPage_Heading = "About Us";

export const jobDescriptionPage_Info = `Codeparva is India's first product-based startup, focusing on the self-storage industry. We create software for both large and small businesses
that want to manage their physical storage units. Brilliant work culture, amazing perks and super talented people drive Codeparva.`;

export const main_section = [
  {
    section_h2: `Open <span class="is-cp-primary-color">Position</span>`,
    section_para: "",
    // "Our interview process is geared to identify & recruit technology leaders who can not only help build a superior tech platform but also be a perfect fit for our company culture.",
    sub_section: [
      {
        id: 3,
        sub_section_img: { section_img: img.SDET, section_alt: "" },
        sub_section_h4: "Human Resources",
        current_opening: [
          {
            image: img.SDET_JD,
            seo_content: {
              title: [
                {
                  Title: "Job openings |  Senior HR",
                },
              ],
              description: [
                {
                  property: "description",
                  content:
                    "Hiring Senior HR.",
                },
              ],
              ogGraph: [
                {
                  property: `og:description`,
                  content: `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  property: `og:title`,
                  content: `Job openings | Senior HR`,
                },
                {
                  property: `og:site_name`,
                  content: `CodeParva Technologies Pvt. Ltd.`,
                },
                {
                  property: `og:url`,
                  content: `${process.env.REACT_APP_URL}/open-positions/senior-hr`,
                },
                {
                  property: `og:image`,
                  content: "https://codeparva.com/images/main-logo.svg",
                },
              ],
              twitterCard: [
                {
                  name: `twitter:site`,
                  content: `CodeParva Technologies Pvt. Ltd.`,
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:creator`,
                  content: `@codeparva`,
                },
                {
                  name: `twitter:title`,
                  content: `Job openings | Senior HR`,
                },
                {
                  name: `twitter:description`,
                  content: `Hiring Senior HR.`,
                },
                {
                  name: `twitter:image`,
                  content: "https://codeparva.com/images/main-logo.svg",
                },
              ],
              script: {
                "@context": "https://schema.org/",
                "@type": "JobPosting",
                title: "Senior HR",
                url: "https://codeparva.com/jobs/bengaluru/open-positions/ui-ux-designer",
                description: `Hiring Senior HR.`,
                hiringOrganization: {
                  "@type": "Organization",
                  name: "CodeParva Technologies Pvt. Ltd.",
                  sameAs: "www.codeparva.com",
                  logo: `https://codeparva.com/${img.MAIN_LOGO}`,
                },
                industry: "Software Company",
                employmentType: "FULL_TIME",
                workHours: "09.00-18.00",
                datePosted: "2023-10-13",
                validThrough: "2023-12-01",
                jobLocation: {
                  "@type": "Place",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress:
                      "No. #45, Second Floor, 24th Main Road, J.P. Nagar 7th Phase.",
                    addressLocality: "bengaluru",
                    addressRegion: "karnataka",
                    postalCode: "560078",
                    addressCountry: "IN",
                  },
                },
                baseSalary: {
                  "@type": "MonetaryAmount",
                  currency: "INR",
                  value: {
                    "@type": "QuantitativeValue",
                    minValue: 600000,
                    maxValue: 1000000,
                    unitText: "YEAR",
                  },
                },
                responsibilities: `Work on exciting small-scale projects and collaborate with a mentor or peer to bring business ideas to life.`,
                skills:
                  "Someone with basic knowledge of UI/UX designing and interest to learn more. ",
                qualifications: " Any Graduates",
                educationRequirements: {
                  "@type": "EducationalOccupationalCredential",
                  credentialCategory: "bachelor degree",
                },
                experienceRequirements: {
                  "@type": "OccupationalExperienceRequirements",
                  monthsOfExperience: "6+",
                  description:
                    "Hiring UI/UX Designer. Join our web product testing team",
                },
                experienceInPlaceOfEducation: true,

                jobBenefits:
                  "Medical Insurance, Travel Reimbursement, Food Reimbursement, Maternity and paternity leave, Internet Reimbursement, Relocation Reimbursement",
                totalJobOpenings: "1",
                "directApply ": true,
                jobImmediateStart: true,
              },
            },
            eligibility: {
              heading: "Who should apply ? ",
              content: [
                "5+ years of experience as a Generalist, with at least 3 years mastering recruitment (sourcing, interviewing, you name it!)",
                "Think creatively, tackle any HR challenge, and discover the best solutions through innovative approaches",
                "Foster a positive, inclusive environment where everyone feels valued and excited to come to work",
                "Analyze HR data with precision, turning insights into actionable strategies.",
                "Listen attentively, address employee concerns with care, and find solutions that work for everyone.",
                "Manage benefits, payroll, and other HR tasks with accuracy and efficiency.",
                "Ensure compliance and keep our HR practices up to date. ",
                "Good with networking, negotiation, and detail oriented",
              ],
            },
            designation: `Senior HR`,
            slug: "senior-hr",
            location: "Bengaluru",
            skills: [
              {
                section: "Technical Skills",
                skills: [
                  {
                    data: "Recruitment and Onboarding",
                    required: true,
                  },
                  {
                    data: "Policies and Compliance",
                    required: true,
                  },
                  {
                    data: "Performance Management",
                    required: true,
                  },
                  {
                    data: "Employee Advocate",
                    required: true,
                  },
                  {
                    data: "Employee Engagement",
                    required: true,
                  },
                  {
                    data: "Payroll and Compensation",
                    required: true,
                  },
                  {
                    data: "Data management and Reporting",
                    required: true,
                  },
                  {
                    data: "Other HR Tasks",
                    required: true,
                  },
                ],
              },
              {
                section: "Soft Skills",
                skills: [
                  {
                    data: "Problem Solving Skills",
                    required: true,
                  },
                  {
                    data: "Negotiation Skills",
                    required: true,
                  },
                  {
                    data: "Attention to Detail",
                    required: true,
                  },
                  {
                    data: "Networking",
                    required: true,
                  }
                ],
              },
            ],
            experience: "4-8 years",
            // ctc: "X-Y LPA",
            cta: [
              {
                label: "View More Details",
                url: "role-overview",
                type: "outlined",
              },
              {
                label: "Apply Now",
                url: "application",
                type: "primary",
              },
            ],
            job_description: {
              heading: `Job Responsibilites`,
              content: [
                {
                  heading: `TECHNICAL SKILLS`,
                  content: [
                    `Recruitment: Lead the talent hunt, finding the best fit for every role and building a top-notch team.`,
                    `Onboarding: Guide new recruits through a smooth transition and help them embrace our unique culture.`,
                    `Policy & Compliance: Contribute to the development and improvement of our HR policies with up to date knowledge of labour laws and regulations. Ensure company’s HR Policies and procedures are in compliance with legal requirements.`,
                    `Performance Management: Support the performance appraisal process, ensuring timely completion and accurate documentation. Collaborate with managers and employees on setting objectives, providing feedback, and navigating PIPs.`,
                    `Employee Advocate: Foster open communication, resolve concerns, and cultivate positive work experience for everyone. `,
                    `Employee Engagement: Design and implement creative initiatives to boost morale and keep our team inspired `,
                    `Data management and Reporting: Maintain precise and confidential employee records, leveraging data analysis to inform strategic HR decision-making`,
                    `Payroll and Compensation: Manage employee payroll and benefits programs like medical insurance. Assist employees with enrolments, changes, and ensure smooth administration.`,
                    `Other HR Tasks: Play a pivotal role in HR policy revisions, conduct compensation studies, and manage administrative tasks including leave tracking and HR tool utilization.`,
                  ],
                },
                {
                  heading: `SOFT SKILLS`,
                  content: [
                    `Problem Solving Skills: Think creatively, tackle any HR challenge, and discover the best solutions through innovative approaches`,
                    `Negotiation Skills: Should have good negotiation skills in terms of employee, benefits, and workplace related issues. `,
                    `Attention to Detail: Accuracy while handling any task is must for this role. `,
                    `Networking: Building and maintaining network with various HR communities to be well informed of latest trends.`
                  ],
                },
              ],
            },
            description: [
              {
                heading: `What it's like to work here`,
                content: [
                  {
                    list: `You will be part of various discussion and decision making with different Stakeholders.`,
                  },
                  {
                    list: `As the primary point of contact for employee queries, you will play a pivotal role in fostering a supportive and responsive work environment.`,
                  },
                  {
                    list: `You will contribute to the cultivation and preservation of our organizational culture, upholding its core values and ethos. `,
                  }
                ],
              },
            ],
          },
        ],
        sub_section_para: "",
      },
    ],
  },
  {
    section_h2: `Hiring <span class="is-cp-primary-color">Process</span>`,
    section_para:
      "Our interview process is geared to identify & recruit technology leaders who can not only help build a superior tech platform but also be a perfect fit for our company culture.",

    sub_section: [
      {
        sub_section_img: { section_img: img.HUMAN_CONNECT, section_alt: "" },
        sub_section_h4: "1. HR Connect",
        sub_section_para:
          "Telephonic conversation to gather basic details and company introduction.",
      },
      {
        sub_section_img: { section_img: img.INTRO_CALL, section_alt: "" },
        sub_section_h4: "2. Intro call",
        sub_section_para:
          "Telephonic conversation to answer candidate queries and assess fitment to existing or new roles.",
      },
      {
        sub_section_img: { section_img: img.PROBLEM_SOLVING, section_alt: "" },
        sub_section_h4: "3. Problem Solving <br/>( Technical  1 )",
        sub_section_para:
          "This will be a coding round, where we will ask you to provide solutions to real world problems or DSA.",
      },
      {
        sub_section_img: { section_img: img.DESIGN_ROUND, section_alt: "" },
        sub_section_h4: "4. Design Round <br/>( Technical 2 )",
        sub_section_para:
          "This will be a technical design round where we try to understand your thought process behind designing a large scale application.",
      },
      {
        sub_section_img: { section_img: img.OPEN_CONVO, section_alt: "" },
        sub_section_h4: "5. Interview Conclusion",
        sub_section_para:
          "Open-ended conversations with HR/CTO based on the interview experience so far.",
      },
    ],
  },
];

export const perks_and_benifis = {
  heading: "Why Us ?",
  content: [
    `You will be a key player in a rapidly growing startup making a real impact.`,
    `You will work with a supportive team where your ideas are valued and heard. `,
    `Bi-annual appraisals, personal development plans, and constant learning opportunities.`,
    `Reimbursements for Food, travel, certifications, internet`
  ],
};
