import React, { Component } from "react";

//for get images
//import components
import { TechBox } from "../../Components/TechBox/techBox";
import { HeaderSection } from "../../Components/headerSection/header";
import { OurStorySection } from "../../Components/ourStorySection/ourStorySection";
import { OurProductSection } from "../../Components/ourProductSection/ourProductSection";
import Storage from "../../Components/storage";
import { WorkWithUsHeaderSection } from "../../Components/workWithUsHeaderSection/header";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { script, ogGraph, twitterCard, Title, Description } from "./config";
import { Layout } from "../../Components/layout/Layout";

export class HomePage extends Component {
  state = {
    isMobile: window.screen.width <= 768,
    readMoreOurStory: false,
    readMoreOurProduct: false,
    readMoreStorage: false,
    isModalOpen: false,
  };

  updateDimensions() {
    this.setState({
      isMobile: window.screen.width <= 768,
    });
  }

  componentDidMount() {
    this.setState({ opacity: "0" });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = document.body.scrollHeight;
        if (currentScrollPos > 0 && currentScrollPos < maxScroll) {
          this.setState({ opacity: "1" });
        } else {
          this.setState({ opacity: "0" });
        }
      };
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  readMoreOurStory = () => {
    this.setState({ readMoreOurStory: !this.state.readMoreOurStory });
  };

  readMoreOurProduct = () => {
    this.setState({ readMoreOurProduct: !this.state.readMoreOurProduct });
  };

  readMoreStorage = () => {
    this.setState({ readMoreStorage: !this.state.readMoreStorage });
  };

  render() {
    const onScrollToTop = () => {
      if (window && window.scrollTo) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    const updateScript = script;
    return (
      <Layout
        script={updateScript}
        ogGraph={ogGraph}
        twitterCard={twitterCard}
        Title={Title}
        Description={Description}
      >
        <div className="p-0 d-flex flex-column mt-1">
          <HeaderSection isMobile={this.state.isMobile} />
          <OurStorySection
            isMobile={this.state.isMobile}
            readMore={this.state.readMoreOurStory}
            toggle={this.readMoreOurStory}
          />
          <OurProductSection
            isMobile={this.state.isMobile}
            readMore={this.state.readMoreOurProduct}
            toggle={this.readMoreOurProduct}
          />
          <Storage
            isMobile={this.state.isMobile}
            readMore={this.state.readMoreStorage}
            toggle={this.readMoreStorage}
          />
          <div style={{ padding: "2rem 0 4rem" }}>
            <TechBox isMobile={this.state.isMobile} />
          </div>
          <div style={{ padding: "3rem 0 6rem" }}>
            <WorkWithUsHeaderSection
              isMobile={this.state.isMobile}
              isHome={true}
            />
          </div>
          <FontAwesomeIcon
            icon={faArrowUp}
            onClick={onScrollToTop}
            style={{
              color: "rgb(143, 212, 230)",
              zIndex: 999,
              fontSize: "20px",
              alignItems: "right",
              position: "fixed",
              right: "5%",
              bottom: "8%",
              cursor: "pointer",
              opacity: `${this.state.opacity}`,
              scrollBehavior: "smooth",
            }}
          />
        </div>
      </Layout>
    );
  }
}
