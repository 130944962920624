import React, { Component } from "react";
import { WorkWithUsHeaderSection2 } from "../../Components/workWithUsHeaderSection2/header";
import { ogGraph, twitterCard, Title, Description } from "./config";
import { Layout } from "../../Components/layout/Layout";
export class WorkWithUsPage extends Component {
  state = {
    isMobile: window.screen.width <= 768,
    isModalOpen: false,
  };

  updateDimensions() {
    this.setState({
      isMobile: window.screen.width <= 768,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <Layout
        ogGraph={ogGraph}
        twitterCard={twitterCard}
        Title={Title}
        Description={Description}
      >
        <div>
          <div className="p-0 d-flex flex-column mt-1">
            <div className="mobile-padd" style={{ padding: "0rem 0 4rem" }}>
              <WorkWithUsHeaderSection2 isMobile={this.state.isMobile} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
