import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import {
  jobDescriptionPage_Heading,
  jobDescriptionPage_Info,
  main_section as  openPositionsData,
  perks_and_benifis,
} from "../CareerPage/config";
import { JobDescriptionHeading } from "../../Components/jobDescriptionHeading/jobDescriptionHeading";
import { JobDescriptionSection } from "../../Components/jobDescriptionSection/jobDescriptionSection";
// import { JobDescriptionNavbar } from "../../Components/jobDescriptionNavbar/jobDescriptionNavbar";
import JobApplyPopupModal from "../../Components/jobApplyPopupModal/modal";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { Layout } from "../../Components/layout/Layout";

export const JobDescriptionPage = () => {
  const {
    state, pathname
  } = useLocation() || {};
  const { sub_section: subSection } = openPositionsData[0];
  let currentOpenings = [];
  subSection.forEach(({current_opening: currentOpening}) => currentOpenings.push(currentOpening));
  currentOpenings = currentOpenings.flat(1)
  const getOpeningInfo = () => currentOpenings.filter(({slug}) => pathname?.split("/").pop() === slug);
  const  { position, clickedfrom } = state || {};
  const JobDescription = position || getOpeningInfo()[0];
  const { seo_content, image } = JobDescription;
  const  { script, description, title, ogGraph, twitterCard } = seo_content || {}; 

  useEffect(() => {
    if (clickedfrom === "applynow") {
      document.getElementById("tab2").click();
    }
  });
  return (
    <Layout
      script={script}
      ogGraph={ogGraph}
      twitterCard={twitterCard}
      Title={title}
      Description={description}
    >
      <div className="JobDescriptionPage">
        {/* <JobDescriptionNavbar /> */}
        <JobDescriptionHeading
          designation={JobDescription.designation}
          location={JobDescription.location}
          cta={JobDescription.cta}
          period={JobDescription.period}
          image={image}
        />
        <Tabs className="container" defaultActiveKey="tab-1">
          <Tab id="tab1" eventKey="tab-1" title="Role Overview">
            <JobDescriptionSection
              jobdescription={JobDescription}
              jobDescriptionPage_Heading={jobDescriptionPage_Heading}
              jobDescriptionPage_Info={jobDescriptionPage_Info}
              perks_and_benifis={perks_and_benifis}
            />
          </Tab>
          <Tab id="tab2" eventKey="tab-2" title="Application">
            <JobApplyPopupModal />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};
