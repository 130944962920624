import React, { useEffect } from "react";
import { Heading } from "../../Helpers/Heading/Heading";
import { CTA } from "../../Helpers/Button/Button";
import { locationIMG, experience } from "../../constants/images";
import { tabletscreen } from "../../constants/screen";

export const JobDescriptionHeading = ({
  designation,
  location,
  cta,
  period,
  image,
}) => {
  const handleApplyPosition = () => {
    document.getElementById("tab2").click();
  };

  useEffect(() => {
    if (window && window.scrollTo) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  });

  const width = window.screen.width;
  return (
    <div className="is-background-gradient">
      <div className="container jobDescHeading d-flex  justify-content-between align-items-center  flex-lg-row ">
        <div className="col-md-6 col-12">
          <Heading
            className="pb-2"
            RenderAs="h2"
            dataText={{ __html: designation }}
          ></Heading>
          <div className="d-flex JDJumbotron">
            {location && (
              <div className="d-flex pr-5 align-items-end">
                <img src={locationIMG} alt="location" />
                <div className="is-cp-body-text-3 pl-3">{location}</div>
              </div>
            )}
            {period && (
              <div className="d-flex align-items-end">
                <img src={experience} alt="experience" />
                <div className=" is-cp-body-text-3 pl-3">{period}</div>
              </div>
            )}
          </div>
          <CTA
            icon=""
            className="is-cp-primary-small-button mt-lg
                            -0 mb-0 mt-4"
            onClick={() => handleApplyPosition()}
          >
            {cta[1].label}
          </CTA>
        </div>
        {width >= tabletscreen && (
          <div className="col-md-4 col-12 JDJumbotronImage">
            <img src={image} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};
