import * as image from "../../constants/images";

export const description = `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`;
export const script = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "CodeParva Technologies pvt. ltd.",
  url: "https://codeparva.com/",
  logo: `https://codeparva.com/${image.MAIN_LOGO}`,
  address: {
    "@type": "PostalAddress",
    streetAddress:
      "2nd Floor, #45 24th Main Road next to Shobha Tulip Apartments,Ramaiah Garden, JP Nagar 7th Phase",
    addressLocality: "Bengaluru",
    postalCode: "560078",
    addressCountry: "IN",
  },
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    opens: "09:00",
    closes: "18:00",
  },
  email: "hireme@codeparva.in",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "",
    contactType: "customer service",
    areaServed: "IN",
    availableLanguage: "en",
  },
};

export const Title = [
  {
    Title: "CodeParva Technologies Pvt. Ltd.",
  },
];

export const Description = [
  {
    property: "description",
    content:
      "The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.",
  },
];

export const ogGraph = [
  {
    property: `og:description`,
    content: `${description}`,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    property: `og:title`,
    content: `${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    property: `og:site_name`,
    content: `${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    property: `og:url`,
    content: `${process.env.REACT_APP_URL}`,
  },
  {
    property: `og:image`,
    content: `https://codeparva.com/images/main-logo.svg`,
  },
];

export const twitterCard = [
  {
    name: `twitter:site`,
    content: `@${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: `@codeparva`,
  },
  {
    name: `twitter:title`,
    content: `@${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    name: `twitter:description`,
    content: `${description}`,
  },
  {
    name: `twitter:image`,
    content: `https://codeparva.com/images/main-logo.svg`,
  },
];
